import React from 'react'
import styled from 'styled-components';
import { SectionTitle } from '../../style/typography';
import { colors } from '../../style/colors';

const Container = styled.section`
    background: ${colors.main};
    color: white;
    position: relative;
`;
const Cover = styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    z-index: 0;
`;
const Content = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
    position: relative;
`;
const Copy = styled.p`
    max-width: 500px;
`;

const AboutMap = ({ data }) => {

    return (
        <Container>
            <Cover src={require('../../images/about-us-map.svg')} />
            <Content>
                <SectionTitle>{data.acf.map_title}</SectionTitle>
                <Copy>{data.acf.map_copy}</Copy>
            </Content>
        </Container>
    )
}

export default AboutMap