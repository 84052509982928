import React from 'react'
import styled from 'styled-components';
import Layout from '../components/layout';
import { SplitColumn } from '../components/split-column';
import { colors } from '../style/colors';
import CoverImage from '../components/cover-image';
import { SectionTitle } from '../style/typography';
import AboutMap from '../components/about/about-map';
import AboutContact from '../components/about/about-contact';
import Img from "gatsby-image"
import SEO from '../components/seo';

const Content = styled.div`
  background: ${colors.main};
  color: white;
  padding: 15% 10%;

  @media (max-width: 550px) {
      padding: 30px;
  }
`;
const Copy = styled.div`
    
    font-family: "Akkurat";
    p {
        font-family: "Akkurat";
        max-width: 500px;
        font-size: 16px;
        margin: 0;
    }
`;
const ImageContainer = styled.div`
    position: relative;
    max-height: 90vh;
    overflow: hidden;
`;

const AboutPage = ({ data }) => {

    return (
        <Layout>
            <SEO title="About Us" />
            <SplitColumn>
                <Content>
                    <SectionTitle>{data.about.acf.intro_title}</SectionTitle>
                    <Copy>{data.about.acf.intro_copy}</Copy>
                </Content>
                <ImageContainer>
                    <Img
                        style={{
                        width: "100%",
                        height: "100%",
                        margin: 0,
                        }}
                        fadeIn
                        objectFit="cover"
                        fluid={data.about.acf.intro_image.localFile.childImageSharp.fluid}
                    />
                </ImageContainer>
            </SplitColumn>
            <SplitColumn>
                <Content style={{backgroundColor: 'white'}}>
                    <CoverImage contain image={require('../images/building.jpg')} /> 
                </Content>
                <Content style={{backgroundColor: 'white'}}>
                    <Copy style={{color: colors.copy}} dangerouslySetInnerHTML={{__html: data.about.acf.section_copy}} />
                </Content>
            </SplitColumn>
            <AboutMap data={data.about} />
            <AboutContact data={data.about} />
        </Layout>
    )
}

export default AboutPage
export const AboutQuery = graphql`
  query AboutPageQuery {
    about: wordpressPage(title: {eq: "About"}) {
      title
      acf {
        closing_copy
        map_title
        map_copy
        intro_title
        intro_copy
        section_copy
        closing_title
        intro_image {
            source_url
            localFile {
              childImageSharp {
                fluid(maxHeight: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
    }
  }

`